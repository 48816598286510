import { Icon, IconProps } from '@/components/Icon';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export type VideoSliderHeaderArrowProps = IconProps;

export const VideoSliderHeaderArrowComponent: StandaloneComponent<VideoSliderHeaderArrowProps> = (props) => {
  return (
    <Icon
      {...mergeProps(
        {
          name: 'sliderForwardArrow',
          options: { className: `w-1.75` },
        } as IconProps,
        props,
      )}
    />
  );
};
