import { useStable, useStableOptions } from '@/hooks/useStable';
import { ClassNameProvider } from '@/styles/SliderSwiper';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode, useCallback, useId, useState } from 'react';
import SwiperReact from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper } from './Swiper';

const modules = [Autoplay, Navigation];

export interface StandaloneSliderSwiperProps extends StandaloneComponentProps<typeof Swiper> {
  arrow?: ReactNode;
  content?: ReactNode;
  hideNavigation?: boolean;
  title?: ReactNode;
}

export const StandaloneSliderSwiper: StandaloneComponent<StandaloneSliderSwiperProps> = ({
  arrow,
  content,
  hideNavigation,
  title,
  options,
  ...props
}) => {
  const uid = useId();

  const { $group, $arrow, $content, $title, $navigation: _navigation, ...$base } = useStableOptions(options);
  const { $item, ...$navigation } = useStableOptions(_navigation);

  // TODO slider navigation in this form doesn't make sense
  // for scenarions where more then 1 slide is visible at once
  const [activeSlide, setActiveSlide] = useState(0);

  const onSlideChange = useCallback((swiper: SwiperReact) => {
    setActiveSlide(swiper.realIndex);
  }, []);

  const stableProviderProps = useStable({
    value: $base,
    options: {
      fallbacks: {
        size: 'variant',
        colors: 'variant',
      },
    } as const,
  });

  const stableSwiperProps = useStable(
    {
      navigation: {
        prevEl: `[id="${uid}"] [data-direction="prev"]`,
        nextEl: `[id="${uid}"] [data-direction="next"]`,
      },
      onSlideChange: !hideNavigation && onSlideChange,
    },
    $base,
    props,
  );

  const slidesCount = Array.isArray(content) ? content.length : 0;

  return (
    <ClassNameProvider {...stableProviderProps}>
      <Swiper.Group {...$group} id={uid}>
        <Swiper.Arrow data-direction="prev" {...$arrow}>
          {arrow}
        </Swiper.Arrow>
        <Swiper.Content {...$content}>
          {title && <Swiper.Title {...$title}>{title}</Swiper.Title>}
          <Swiper modules={modules} {...stableSwiperProps}>
            {content}
          </Swiper>
        </Swiper.Content>
        <Swiper.Arrow data-direction="next" {...$arrow}>
          {arrow}
        </Swiper.Arrow>
      </Swiper.Group>
      {(!hideNavigation || !slidesCount) && (
        <Swiper.Navigation {...$navigation}>
          {Array.from({ length: slidesCount }).map((_, index) => (
            <Swiper.Navigation.Item key={index} data-active={index === activeSlide} {...$item} />
          ))}
        </Swiper.Navigation>
      )}
    </ClassNameProvider>
  );
};
